@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

:root {
	--mainColor: #FF5A5A;
	--mainColorLight: #FBA3A3;
	--secondaryColor: #3EB2FA;
	--textColor: #eee;
}

header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 80px;
	padding: 0 2rem;
	background-color: #fff;
	color: #fff;
}

nav a {
	margin: 0 1rem;
	color: var(--mainColor);
	text-decoration: none;
}

nav a:hover {
	color: var(--secondaryColor);
}

header .nav-btn {
	padding: 5px;
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
	color: var(--mainColor);
	visibility: hidden;
	opacity: 0;
	font-size: 1.8rem;
}

header div,
nav {
	display: flex;
	align-items: center;
}

@media only screen and (max-width: 1024px) {
	header .nav-btn {
    visibility: visible;
		opacity: 1;
	}

	header nav {
		position: fixed;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 1.5rem;
		background-color: #fff;
		transition: 1s;
		transform: translateY(-100vh);
    z-index: 999;
	}

	header .responsive_nav {
		transform: none;
	}

	nav .nav-close-btn {
		position: absolute;
		top: 2rem;
		right: 2rem;
	}

	nav a {
		font-size: 1.5rem;
	}
}


.ant-btn-primary {
  background-color: #FF5A5A !important;
}

.ant-btn-danger {
  background-color: #FF5A5A !important;
  color: white;
}

.ant-pagination-item-active {
  border-color: #FF5A5A !important;
}

li.ant-pagination-item-active a {
  color: #FF5A5A !important;
}

.ant-menu-item-selected { 
  background-color: #FF5A5A !important;
}

body,
h1,
h2,
h3,
p {
  margin: 0;
}


h1,
h2,
h3 {
  font-weight: 900;
  line-height: 1;
}

img {
  display: block;
  max-width: 100%;
}

.event-card {
  display: block;
  max-width: 100%;
}

.text-center {
  text-align: center;
}

.container {
  padding: 0 1em;
}

.map {
  width: 80%;
  height: 50vh;
  position: relative;
}

.location-icon {
  font-size: 3rem;
}
.location-icon-hue {
  font-size: 8rem;
}

.header {
  padding: 10px;
  background-color: #FF5A5A;
  color: #fff;
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  bottom: 500;
  z-index: 100;
}

.header h1 {
  font-size: 25px;
  padding: 0;
  margin: 0;
  text-align: center;
}


.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.loader img {
  width: 400px;
}

.loader h1 {
  margin-top: -100px;
}


.location-info {
  position: absolute;
  top: 80px;
  right: 50px;
  width: 400px;
  min-height: 200px;
  padding: 20px;
  /* background-color: rgba(0, 0, 0, 0.6); */
  border-radius: 10px;
  font-size: 24px;
  color: #fff;
}

.location-info ul {
  list-style-type: none;
  padding: 0;
}

.location-info li {
  padding: 5px 0;
}

@media only screen and (max-width: 1024px) {

  .location-info {
    padding-top: 10px;
    position: relative;
    top: 0px;
    right: 0px;
    width: 100%;
    min-height: 0px;
    padding: 0px;
    /* background-color: rgba(0, 0, 0, 0.6); */
    border-radius: 10px;
    font-size: 16px;
    color: #fff;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  
  .location-info ul {
    list-style-type: none;
    padding: 0;
  }
  
  .location-info li {
    padding: 5px 0;
  }

  .footer {
    padding-top: 100px;
    top: 500px;
  }

}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
}

.loader img {
  width: 400px;
}

.loader h1 {
  margin-top: -10px;
}

.parking-options-list {
  text-align: 'left';
  align-items: 'left';
  justify-content: 'center';
  padding: 50;
  width: '100%' ;
}



